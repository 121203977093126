define("ember-math-helpers/helpers/cos", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cos = cos;
  _exports.default = void 0;
  /**
   * Executes `Math.cos` on the number passed to the helper.
   *
   * ```hbs
   * {{cos a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.cos`
   * @return {number} The cos of the passed number
   */
  function cos([number]) {
    return Math.cos(number);
  }
  var _default = (0, _helper.helper)(cos);
  _exports.default = _default;
});